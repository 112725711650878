export const cloud1BuildInImagePath = "images/cloud1_buildin/";
export const cloud1BuildOutImagePath = "images/cloud1_buildout/";

export const cloud2BuildInImagePath = "images/cloud2_buildin/";
export const cloud2BuildOutImagePath = "images/cloud2_buildout/";

export const cloud3ImagePath = "images/cloud3/";

export const sunImageLottie = "lottie/sun.lottie";

export const pipeImagePath = "images/pipe/";

export const pipeLottie = "lottie/pipe.lottie";
export const dropletPipeLottie = "lottie/droplet_interact.lottie";
export const cloud1BuildInLottie = "lottie/cloud1_buildin.lottie";
export const cloud1BuildOutLottie = "lottie/cloud1_buildout.lottie";

export const cloud2BuildInLottie = "lottie/cloud2_buildin.lottie";
export const cloud2BuildOutLottie = "lottie/cloud2_buildout.lottie";

export const cloud3Lottie = "lottie/cloud3.lottie";
export const cloud3BuildOutLottie = "lottie/cloud3_buildout.lottie";
export const wellWaterLottie = "lottie/wellwater.lottie";
export const textborderLottie = "lottie/textborder.lottie";

export const wellWaterInteractLottie = "lottie/wellwater_interact.lottie";

export const odometer1Path = "lottie/odometer1.lottie";
export const odometer2Path = "lottie/odometer2.lottie";
export const odometer3Path = "lottie/odometer3.lottie";
export const odometer4Path = "lottie/odometer4.lottie";

export const levelupPath = "lottie/levelup.lottie";