import React, { useState, useEffect } from 'react';
import { Item } from "../../Interfaces";
import { AnimateImages } from './AnimateImages';
import '../AnimateItemButton.css';

export interface AnimateItemProps {
    item: Item,
    loop: boolean
    stop: boolean;
    hideButton: boolean;
    onEnd: () => void;
    onTap?: () => void;
}

export const AnimateItem: React.FC<AnimateItemProps> = ({ stop, loop, item, hideButton, onEnd, onTap }: AnimateItemProps) => {
    const [imageIndex, setImageIndex] = useState(0);
    const [showButton, setShowButton] = useState(false);

    const [isAnimation, setIsAnimation] = useState(true);

    useEffect(() => {
        setIsAnimation(item.images != undefined);
        setImageIndex(0);
    }, [])

    function getImage(): string[] {
        if (item.images == undefined)
            return [];
        var i;
        var images: string[] = [];
        var imageItem = item.images[imageIndex];
        for (i = imageItem.start; i <= imageItem.end; i++) {
            var path = imageItem.path;
            var name = "000000" + i
            var image: any = path + name.slice(-imageItem.digits) + ".png";

            images.push(image);
        }

        return images;
    }

    function getImagePath(): string {
        if (item.images == undefined)
            return ""
        var imageItem = item.images[imageIndex];
        return imageItem.path
    }

    const localEnd = () => {
        if (item.images == undefined)
            return ;
        if (item.images.length == 1) {
            onEnd();
        } else {
            if (imageIndex == 0)
                setTimeout(() => {
                    setImageIndex(1);
                }, item.delay);
            else {
                setTimeout(() => {
                    onEnd();
                }, item.delay);
            }
        }
    }

    const onCallback = (index: number) => {
        if (item.button) {
            if (index >= item.button.start && index <= item.button.end)
                setShowButton(true);
            else
                setShowButton(false);
        }
    }

    return (
        <>
        {isAnimation &&
            <>
            <AnimateImages
                images={getImage()}
                imagePath={getImagePath()}
                loop={loop}
                style={{ width: item.size.width, height: item.size.height, top: item.position.top, left: item.position.left, position: 'absolute' }}
                interval={item.interval}
                stop={stop}
                onEnd={localEnd}
                onCallback={onCallback}
            />

            {showButton && !hideButton &&
                <button className='AnimateItemButton' style={{ width: item.size.width, height: item.size.height, top: item.position.top, left: item.position.left, position: 'absolute'}} onClick={onTap} />
            }
            </>
        }
        {!isAnimation && item.image
        &&
        <img src={item.image} alt='' style={{ width: item.size.width, height: item.size.height, top: item.position.top, left: item.position.left, position: 'absolute'}}  />
        }
        </>
    );
};