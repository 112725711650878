import { Item, Lottie } from "./Interfaces";
import { cloud1BuildInImagePath, cloud1BuildOutImagePath } from './AnimateImagePaths';
import { cloud2BuildInImagePath, cloud2BuildOutImagePath } from './AnimateImagePaths';
import { cloud3ImagePath,pipeImagePath } from './AnimateImagePaths';
import {cloud1BuildInLottie, cloud1BuildOutLottie, cloud2BuildInLottie, cloud2BuildOutLottie, cloud3Lottie, cloud3BuildOutLottie, sunImageLottie, pipeLottie, wellWaterLottie, dropletPipeLottie, textborderLottie, wellWaterInteractLottie, odometer1Path, odometer2Path, odometer3Path, odometer4Path, levelupPath} from './AnimateImagePaths';

export const Empty: Item = {
    size: {
        width: 0,
        height: 0
    },
    position: {
        top: 0,
        left: 0
    },
    interval: 0,
    delay: 0
}

export const Sun: Lottie = {
    size: {
        width: 190,
        height: 160
    },
    position: {
        top: 30,
        left: 670
    },
    lottie: [
        sunImageLottie
    ],
    interval: 40,
    delay: 0,
    audio: {
        path: "audio/background_tune.mp3",
        loop: true
    }
};

export const Cloud1: Lottie = {
    size: {
        width: 420,
        height: 120,
    },
    position: {
        top: 90,
        left: 416
    },
    lottie: [
        cloud1BuildInLottie,
        cloud1BuildOutLottie
    ],
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const Cloud2: Lottie = {
    size: {
        width: 275,
        height: 150,
    },
    position: {
        top: 65,
        left: 640
    },
    lottie: [
        cloud2BuildInLottie,
        cloud2BuildOutLottie
    ],
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const Cloud3: Lottie = {
    size: {
        width: 320,
        height: 280,
    },
    position: {
        top: 24,
        left: 574
    },
    lottie: [
        cloud3Lottie
    ],
    button: {
        start: 100,
        end: 550
    },
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/thunder_droplet.mp3",
        delay: 3900
    }
};

export const Cloud3Buildout: Lottie = {
    size: {
        width: 320,
        height: 280,
    },
    position: {
        top: 24,
        left: 574
    },
    lottie: [
        cloud3BuildOutLottie
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
};

export const Well: Item = {
    ...Empty,
    size: {
        width: 919,
        height: 596,
    },
    position: {
        top: 464,
        left: 18
    },
    image: "images/well_cistern.png"
}

export const WaterBar: Item = {
    ...Empty,
    size: {
        width: 500,
        height: 32,
    },
    position: {
        top: 478,
        left: 416
    }
}


export const Pipe: Lottie = {
    size: {
        width: 560,
        height: 340,
    },
    position: {
        top: 218,
        left: 11
    },
    lottie: [
        pipeLottie
    ],
    button: {
        start: 100,
        end: 406
    },
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/pipe_droplet.mp3"
    }
};

export const DropletPipe: Lottie = {
    size: {
        width: 277,
        height: 250,
    },
    position: {
        top: 308,
        left: 380
    },
    lottie: [
        dropletPipeLottie
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/droplet_interaction.mp3"
    }
};

export const DropletCloud: Lottie = {
    size: {
        width: 277,
        height: 250,
    },
    position: {
        top: 110,
        left: 580
    },
    lottie: [
        dropletPipeLottie
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/droplet_interaction.mp3"
    }
};

export const DropletWellWater: Lottie = {
    size: {
        width: 342,
        height: 200,
    },
    position: {
        top: 850,
        left: 30
    },
    lottie: [
        wellWaterInteractLottie
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/droplet_interaction.mp3"
    }
};

export const PipeStatic: Item = {
    size: {
        width: 560,
        height: 340,
    },
    position: {
        top: 218,
        left: 11
    },
    image: "images/static_pipe.png",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const TrueButton: Item = {
    size: {
        width: 187,
        height: 187,
    },
    position: {
        top: 797,
        left: 1173
    },
    image: "images/true.png",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const FalseButton: Item = {
    size: {
        width: 187,
        height: 187,
    },
    position: {
        top: 797,
        left: 1520
    },
    image: "images/false.png",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const WellWater: Lottie = {
    size: {
        width: 171,
        height: 100,
    },
    position: {
        top: 950,
        left: 116
    },
    lottie: [
        wellWaterLottie
    ],
    button: {
        start: 100,
        end: 406
    },
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/well_fills.mp3"
    },
    audio2: {
        path: "audio/well_empties.mp3",
        delay: 5000
    }
};

export const StaticLeftScreen: Item = {
    size: {
        width: 937,
        height: 1080,
    },
    position: {
        top: 0,
        left: 0
    },
    image: "images/static_left_screen.png",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const TextQuestion1: Item = {
    size: {
        width: 750,
        height: 164,
    },
    position: {
        top: 413,
        left: 1066
    },
    image: "",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const TextQuestion2: Item = {
    size: {
        width: 750,
        height: 164,
    },
    position: {
        top: 640,
        left: 1066
    },
    image: "",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const ScoreBox: Item = {
    size: {
        width: 330,
        height: 134,
    },
    position: {
        top: 121,
        left: 1276
    },
    image: "",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const ScoreBoxImage: Item = {
    size: {
        width: 330,
        height: 134,
    },
    position: {
        top: 121,
        left: 1276
    },
    image: "images/score_box.png",
    interval: 11,
    delay: 100, // it should go up to 15s
}

export const ScoreLevelBox: Item = {
    size: {
        width: 330,
        height: 60,
    },
    position: {
        top: 115,
        left: 1280
    },
    image: "",
    interval: 11,
    delay: 100, // it should go up to 15s
}

export const ScoreScoreBox: Item = {
    size: {
        width: 330,
        height: 60,
    },
    position: {
        top: 155,
        left: 1290
    },
    image: "",
    interval: 11,
    delay: 100, // it should go up to 15s
}

export const TextBorder: Lottie = {
    size: {
        width: 960,
        height: 400,
    },
    position: {
        top: 360,
        left: 960
    },
    lottie: [
        textborderLottie
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
}

export const TextBorderImage: Item = {
    size: {
        width: 960,
        height: 400,
    },
    position: {
        top: 360,
        left: 960
    },
    image: "images/textborder.png",
    interval: 11,
    delay: 100, // it should go up to 15s
}

export const QuestionBox: Item = {
    size: {
        width: 870,
        height: 280,
    },
    position: {
        top: 420,
        left: 1005
    },
    image: "",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const CountDown: Item = {
    size: {
        width: 200,
        height: 200,
    },
    position: {
        top: 23,
        left: 380
    },
    image: "",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const Odometer1: Lottie = {
    size: {
        width: 920,
        height: 440,
    },
    position: {
        top: 322,
        left: 978
    },
    lottie: [
        odometer1Path
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/odometer_sound.mp3"
    }
    
};

export const Odometer2: Lottie = {
    size: {
        width: 920,
        height: 440,
    },
    position: {
        top: 322,
        left: 978
    },
    lottie: [
        odometer2Path
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    
};

export const Odometer3: Lottie = {
    size: {
        width: 920,
        height: 440,
    },
    position: {
        top: 322,
        left: 978
    },
    lottie: [
        odometer3Path
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    
};

export const Odometer4: Lottie = {
    size: {
        width: 920,
        height: 440,
    },
    position: {
        top: 322,
        left: 978
    },
    lottie: [
        odometer4Path
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    
};

export const LevelUp: Lottie = {
    size: {
        width: 370,
        height: 320,
    },
    position: {
        top: 116,
        left: 524
    },
    lottie: [
        levelupPath
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/levelup.mp3"
    }
    
};

export const CorrectAnswer: Lottie = {
    size: {
        width: 450,
        height: 450,
    },
    position: {
        top: 258,
        left: 1215
    },
    lottie: [
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/correct_response.mp3"
    }
    
};

export const WrongAnswer: Lottie = {
    size: {
        width: 450,
        height: 450,
    },
    position: {
        top: 258,
        left: 1215
    },
    lottie: [
    ],
    interval: 11,
    delay: 100, // it should go up to 15s
    audio: {
        path: "audio/incorrect_response.mp3"
    }
    
};

export const Keimeno1: Item = {
    size: {
        width: 814,
        height: 572,
    },
    position: {
        top: 254,
        left: 1034
    },
    image: "",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const Keimeno2: Item = {
    size: {
        width: 814,
        height: 572,
    },
    position: {
        top: 254,
        left: 1034
    },
    image: "",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const Keimeno2Image: Item = {
    size: {
        width: 814,
        height: 572,
    },
    position: {
        top: 335,
        left: 1034
    },
    image: "images/keimeno2.png",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const Pame: Item = {
    size: {
        width: 330,
        height: 135,
    },
    position: {
        top: 826,
        left: 1271
    },
    image: "images/pame.png",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export const PameXana: Item = {
    size: {
        width: 449,
        height: 135,
    },
    position: {
        top: 844,
        left: 1191
    },
    image: "images/pamexana.png",
    interval: 11,
    delay: 100 // it should go up to 15s
};

export function updateItem(item: Item, scale: number): Item {
    return {
        ...item,
        position: {
            top: item.position.top * scale,
            left: item.position.left * scale
        },
        size: {
            width: item.size.width * scale,
            height: item.size.height * scale
        }
    }
}

export function updateLottie(item: Lottie, scale: number): Lottie {
    return {
        ...item,
        position: {
            top: item.position.top * scale,
            left: item.position.left * scale
        },
        size: {
            width: item.size.width * scale,
            height: item.size.height * scale
        }
    }
}