import React, { useEffect, useState, useContext, useReducer } from 'react';
import '../Question';
import { SizeContext } from '../../context';
import { Item, TextI } from "../Interfaces";
import { updateItem, ScoreBox, ScoreLevelBox, ScoreScoreBox } from "../Constants";
import { Keimeno1, Keimeno2, Pame } from "../Constants"
import ReactPlayer from 'react-player'
import { ButtonItem } from '../Animations/Image/ButtonItem'

interface EntryRightProps {
    onEnd: () => void;
}

export const EntryRight: React.FC<EntryRightProps> = ({ onEnd }: EntryRightProps) => {

    const [scale, setScale] = useState(1.0);
    const [scoreBox, setScoreBox] = useState<Item>(ScoreBox);

    const [showText1, setShowText1] = useState(false);
    const [showText2, setShowText2] = useState(false);

    const [showScoreBox, setShowScoreBox] = useState(false);
    const [showScoreLevelBox, setShowScoreLevelBox] = useState(false);
    const [scoreLevelBox, setScoreLevelBox] = useState<Item>(ScoreLevelBox);
    const [scoreScoreBox, setScoreScoreBox] = useState<Item>(ScoreScoreBox);

    const [keimeno1, setKeimeno1] = useState<Item>(Keimeno1);
    const [keimeno2, setKeimeno2] = useState<Item>(Keimeno2);

    const [pame, setPame] = useState<Item>(Pame);
    const [showPame, setShowPame] = useState(false);

    const context = useContext(SizeContext);

    useEffect(() => {
        setScoreBox(updateItem(ScoreBox, scale));
        setScoreLevelBox(updateItem(ScoreLevelBox, scale));
        setScoreScoreBox(updateItem(ScoreScoreBox, scale));

        setKeimeno1(updateItem(Keimeno1, scale));
        setKeimeno2(updateItem(Keimeno2, scale));
        setPame(updateItem(Pame, scale));
    }, [scale]);

    useEffect(() => {
        onScreenresize();
        window.addEventListener("resize", handleScreenResize);
        window.addEventListener("orientationchange", handleScreenResize);
        window.screen.orientation.addEventListener("change", handleScreenResize);

        setTimeout(() => {
            setShowText1(true);
        }, 1000);

        return () => {
            window.removeEventListener('resize', handleScreenResize);
            window.removeEventListener('orientationchange', handleScreenResize);
            window.screen.orientation.removeEventListener('change', handleScreenResize);
        };

    }, [])

    const handleScreenResize = () => {
        onScreenresize();
    }

    const onScreenresize = () => {
        resizeScreen();
        var referenceWidth = 1920.0;
        var referenceHeight = 1080.0;

        var windowWidth = context.width;
        var windowHeight = context.height;

        var _scaleWidth = windowWidth / referenceWidth;
        var _scaleHeight = windowHeight / referenceHeight;

        var _scale = Math.min(_scaleHeight, _scaleWidth);

        setScale(_scale);
    }

    const resizeScreen = () => {
        var ratio = 1920.0 / 1080.0;
        var windowHeight = Math.min(window.screen.height, window.innerHeight);
        var windowWidth = Math.min(window.screen.width, window.innerWidth);

        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (webkit) {
            windowHeight = window.innerHeight;
            windowWidth = window.innerWidth;
        }

        var webWidth = windowWidth;
        var webHeight = windowWidth / ratio;

        if (webHeight > windowHeight) {
            webHeight = windowHeight - 2;
            webWidth = webHeight * ratio;
        }

        context.width = Math.min(webWidth, 1920);
        context.height = Math.min(webHeight, 1080);
    }

    const scoreBoxIsPresented = () => {
        setShowScoreLevelBox(true);
    }

    const onEndText1 = () => {
        setTimeout(() => {
            setShowText1(false);
            setShowText2(true);
        }, 2000);
    }

    const onEndText2 = () => {
        setShowScoreBox(true);
        setTimeout(() => {
            setShowPame(true);
        }, 1000);
    }

    return (
        <>

            <div className="container" style={{
                display: showText1 ? "block" : "none",
                width: keimeno1.size.width, height: keimeno1.size.height, top: keimeno1.position.top, left: keimeno1.position.left
            }}>
                <ReactPlayer
                    playing={showText1}
                    url={"video/keimeno1.mp4"}
                    width='100%'
                    height='100%'
                    loop={false}
                    muted={false}
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                    onEnded={onEndText1}
                />
            </div>


            <div className="container" style={{
                display: showText2 ? "block" : "none",
                width: keimeno2.size.width, height: keimeno2.size.height, top: keimeno2.position.top, left: keimeno2.position.left
            }}>
                <ReactPlayer
                    playing={showText2}
                    url={"video/keimeno2.mp4"}
                    width='100%'
                    height='100%'
                    loop={false}
                    muted={false}
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                    onEnded={onEndText2}
                />
            </div>


            <div className="container" style={{
                display: showScoreBox ? "block" : "none",
                width: scoreBox.size.width, height: scoreBox.size.height, top: scoreBox.position.top, left: scoreBox.position.left
            }}>
                <ReactPlayer
                    playing={showScoreBox}
                    url={"video/score_box.mp4"}
                    width='100%'
                    height='100%'
                    loop={false}
                    muted={false}
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                    onEnded={scoreBoxIsPresented}
                />
            </div>

            {showScoreLevelBox &&
                <>
                    <div className="container" style={{
                        width: scoreLevelBox.size.width, height: scoreLevelBox.size.height, top: scoreLevelBox.position.top, left: scoreLevelBox.position.left
                    }}>
                        <span className="level">LEVEL 1
                        </span>
                    </div>

                    <div className="container" style={{
                        width: scoreScoreBox.size.width, height: scoreScoreBox.size.height, top: scoreScoreBox.position.top, left: scoreScoreBox.position.left
                    }}>
                        <span className="score">0000
                        </span>
                    </div>
                </>
            }

            <div style={{ display: showPame ? 'block' : 'none' }}>
                <ButtonItem
                    item={pame}
                    shouldHearClickSound={true}
                    onTap={() => {
                        onEnd()
                    }}
                />

            </div>
        </>
    )
}