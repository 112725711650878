import React, { useState, useEffect, useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { Lottie } from "./Interfaces";

export interface CustomAudioProps {
    item: Lottie,
    loop: boolean;
    start: boolean;
    onAudioEnd?: () => void;
}

export const CustomAudio: React.FC<CustomAudioProps> = ({ start, loop, item, onAudioEnd }: CustomAudioProps) => {
    const [playAudio, setPlayAudio] = useState(false);
    const [playAudio2, setPlayAudio2] = useState(false);

    const [playSound, setPlaySound] = useState(false);

    const myAudio = useRef<HTMLAudioElement>();
    const myAudio2 = useRef<HTMLAudioElement>();

    //    let audio = new Audio(item.audio.path);

    /* useEffect(() => {
         if (item.audio && !item.audio.delay)
             setPlayAudio(true);
         else if (item.audio && item.audio.delay) {
             setTimeout(() => {
                 setPlayAudio(true);
             }, item.audio.delay);
         }
 
         if (item.audio2) {
             setTimeout(() => {
                 setPlayAudio(false);
                 setPlayAudio2(true);
             }, item.audio2.delay);
         }
     }, []);*/

    useEffect(() => {
        if (myAudio.current) {
            if (start) {
                if (!item.audio.delay) {
                    myAudio.current.play()
                } else {
                    setTimeout(() => {
                        myAudio.current.play()
                    }, item.audio.delay);
                }
            } else {
                myAudio.current.pause()
            }

            if (myAudio2.current) {
                if (start) {
                    if (item.audio2) {
                        setTimeout(() => {
                            myAudio.current.play()
                            myAudio2.current.play()
                        }, item.audio2.delay);
                    }
                }
            }
        }
    }, [start]);

    useEffect(() => {
        if (myAudio.current) {
            myAudio.current.addEventListener('ended', onEnded);
        }
    }, [myAudio])

    const onEnded = () => {
        onAudioEnd?.();
    }

    return (
        <div style={{display: 'none'}}>
            <audio ref={myAudio} preload="auto" src={item.audio.path} loop={loop}/>
            {item.audio2 &&
                <audio ref={myAudio2} preload="auto" src={item.audio2.path} />
            }
        </div>
    )
};