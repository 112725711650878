import React, { useState, useEffect } from 'react';
import { Lottie } from "../Interfaces";
import './AnimateItemButton.css';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import ReactAudioPlayer from 'react-audio-player';

export interface AnimateLottieProps {
    item: Lottie,
    removeItemAtEnd: boolean;
    loop: boolean
    stop: boolean;
    hideButton: boolean;
    onEnd: () => void;
    onTap?: () => void;
}

export const AnimateLottie: React.FC<AnimateLottieProps> = ({ stop, removeItemAtEnd, loop, item, hideButton, onEnd, onTap }: AnimateLottieProps) => {
    const [showButton, setShowButton] = useState(false);
    const [dotLottie, setDotLottie] = useState(null);
    const [lottieIndex, setLottieIndex] = useState(0);
    const [isAnimation, setIsAnimation] = useState(true);
    const [playAudio, setPlayAudio] = useState(false);
    const [playAudio2, setPlayAudio2] = useState(false);

    useEffect(() => {
        if (dotLottie) {
            dotLottie.addEventListener("complete", localEnd);
        }

        if (item.button != undefined) {
            setTimeout(() => {
                setShowButton(true);
            }, item.audio.delay ? item.audio.delay : 100);
        }

        return () => {
            if (dotLottie) {
                dotLottie.removeEventListener("complete", localEnd);
            }
        }
    }, [dotLottie]);

    /*useEffect(() => {
        if (item.audio && !item.audio.delay)
           setPlayAudio(true);
       else if (item.audio && item.audio.delay) {
           setTimeout(() => {
               setPlayAudio(true);
           }, item.audio.delay);
       }

       if (item.audio2) {
           setTimeout(() => {
               setPlayAudio(false);
               setPlayAudio2(true);
           }, item.audio2.delay);
       }
    }, []);*/

    useEffect(() => {
        if (lottieIndex >= item.lottie.length) {
            if (removeItemAtEnd) {
                setIsAnimation(false)
            }
            setTimeout(() => {
                onEnd();
            }, item.delay);
        }

    }, [lottieIndex]);


    const localEnd = () => {
        setTimeout(() => {
            setLottieIndex(currCount => currCount + 1);
        }, item.delay);
    }

    return (
        <>
            {isAnimation &&
                <>
                    < div style={{ position: 'absolute', top: item.position.top, left: item.position.left, width: item.size.width, height: item.size.height }}>
                        <DotLottieReact
                            height={item.size.height}
                            width={item.size.width}
                            src={item.lottie[lottieIndex]}
                            autoResizeCanvas={false}
                            loop={loop}
                            autoplay
                            dotLottieRefCallback={setDotLottie}
                        />
                    </div>
                </>
            }
            {showButton && !hideButton &&
                <button className='AnimateItemButton' style={{ width: item.size.width, height: item.size.height, top: item.position.top, left: item.position.left, position: 'absolute' }} onClick={onTap} />
            }
        </>
    )
};