import React, { useEffect, useState, useContext } from 'react';

import { ImageButton } from './Components/ImageButton';
import ReactPlayer from 'react-player'
import './Welcome.css';
import { Cloud1, Cloud2, Cloud3, Pipe } from "./Constants";
import { Item } from "./Interfaces";

interface WelcomeProps {
    onEnd: () => void;
}

export const Welcome: React.FC<WelcomeProps> = ({ onEnd }: WelcomeProps) => {
    const [displayButton, setDisplayButton] = useState(false);
    const [playVideo, setPlayVideo] = useState(true);

    const [buttonWidth, setButtonWidth] = useState(10);
    const [buttonHeight, setButtonHeight] = useState(10);
    const [buttonY, setButtonY] = useState(10);

    const onVideoEnd = () => {
        setDisplayButton(true);
    }

    useEffect(() => {
        getSize();
        window.addEventListener('resize', getSize);
        window.addEventListener("orientationchange", getSize);
        window.screen.orientation.addEventListener("change", getSize);

        setTimeout(() => {
            startPlayingVideo();
        }, 200);

        // prefetchImages(Pipe);
        /*  prefetchImages(Cloud1);
          prefetchImages(Cloud2);
          prefetchImages(Cloud3);*/
        //   setTimeout(startPlayingVideo, 100);

        // 👇️ remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', getSize);
            window.removeEventListener('orientationchange', getSize);
            window.screen.orientation.removeEventListener('change', getSize);
        };
    }, [])

    const getSize = () => {
        var windowHeight = Math.min(window.screen.height, window.innerHeight);
        var windowWidth = Math.min(window.screen.width, window.innerWidth);

        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (webkit) {
            windowHeight = window.innerHeight / window.devicePixelRatio;
            windowWidth = window.innerWidth / window.devicePixelRatio;
        }

        var imageHeight = 1080;
        var imageWidth = 1920;
        setButtonHeight(210 * windowHeight / imageHeight);
        setButtonWidth(610 * windowWidth / imageWidth);

        var ratio = 1920.0 / 1080.0;
        var webWidth = windowWidth;
        var webHeight = windowWidth / ratio;

        if (webHeight > windowHeight) {
            webHeight = windowHeight - 2;
            webWidth = webHeight * ratio;
        }

        setButtonY((webHeight - 161 * windowHeight / imageHeight) / 2.0);

    }


    function prefetchImages(item: Item) {
        if (item.images == undefined)
            return;
        var images: string[] = [];
        var j;
        var i;
        for (j = 0; j < item.images.length; j++) {
            var imageItem = item.images[j];
            for (i = imageItem.start; i <= imageItem.end; i++) {
                var path = imageItem.path;
                var name = "000000" + i
                var image: any = path + name.slice(-imageItem.digits) + ".png";

                images.push(image);

            }
        }

        imagePreloader(images);

    }

    function preloadImage(src: string) {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = function () {
                resolve(img)
            }
            img.onerror = img.onabort = function () {
                reject(src)
            }
            img.src = src
        })
    }

    function imagePreloader(imageList: string[]) {
        async function effect() {
            console.log('PRELOAD')

            const imagesPromiseList: Promise<any>[] = []
            for (const i of imageList) {
                imagesPromiseList.push(preloadImage(i))
            }

            await Promise.all(imagesPromiseList)


        }

        effect();
    }

    const startPlayingVideo = () => {
        let videoPlayer = (document.getElementsByTagName('video') as HTMLCollectionOf<HTMLVideoElement> | null);
        if (videoPlayer) {
            videoPlayer[0].play();
        }
    }

    return (
        <>
            {!playVideo &&
                <div className='Start'>
                    <div style={{ position: 'relative', width: buttonWidth, height: buttonHeight, margin: "auto", top: buttonY }}>
                        <ImageButton
                            image={'images/startbutton.png'}
                            onClick={() => {
                                setPlayVideo(true);
                                setTimeout(startPlayingVideo, 200);
                            }}
                        />
                    </div>
                </div>
            }
            {playVideo &&
                <ReactPlayer
                    playing={false}
                    url={"video/intro.mp4"}
                    width='100%'
                    height='100%'
                    loop={false}
                    muted={true}
                    config={{ file: { attributes: { playsInline: true, }, }, }}
                    onEnded={onVideoEnd}
                />
            }
            {displayButton &&
                <div className='Start'>
                    <div style={{ position: 'relative', width: buttonWidth, height: buttonHeight, margin: "auto", top: buttonY }}>
                        <ImageButton
                            image='images/startbutton.png'
                            onClick={onEnd}
                        />
                    </div>
                </div>
            }
        </>
    );
}
