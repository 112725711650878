import React, { CSSProperties, useEffect, useState } from 'react';

export interface AnimateImagesProps {
    images: string[];
    imagePath: string,
    loop: boolean
    style: CSSProperties;
    interval: number;
    stop: boolean;
    onEnd: () => void;
    onCallback?: (index: number) => void;
}

export const AnimateImages: React.FC<AnimateImagesProps> = ({ images, imagePath, style, interval, stop, loop, onEnd, onCallback }: AnimateImagesProps) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [forcedStop, setForcedStop] = useState(false);
    
    useEffect(() => {
        setForcedStop(false);
        setCurrentImage(0);
        setTimeout(() => {
            animateImages(-1)
        }, interval);
        return (() => {
            setCurrentImage(0);
            setForcedStop(true);
        });
    }, [imagePath]);

    useEffect(() => {
        if (!stop && !forcedStop) {
            setTimeout(() => {
                animateImages(-11)
            }, interval);
        }
    }, [stop])

    const imageIsLoaded = () => {
        if (!stop && !forcedStop) {
            setTimeout(() => {
                animateImages(-11)
            }, interval);
        }
    }

    const imageIsNotLoaded = () => {
        // do something
        console.log("Image is not loaded " + currentImage);
    }

    const animateImages = (index: number) => {
        var newImage = currentImage + 1;
        if (index != -11) 
            newImage = index + 1;

        if (onCallback != undefined)
            onCallback(newImage);

        if (newImage < images.length) {
            setCurrentImage(newImage);
        } else if (loop) {
            setCurrentImage(0);
        } else {
            onEnd();
        }
    }

    return (
        <>
            <img src={images[currentImage]} alt='' style={style} onLoad={imageIsLoaded} onError={imageIsNotLoaded} />
        </>
    );
};