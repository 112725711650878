import React, { useEffect, useContext, useState } from 'react';
import './App.css';
import { Game } from './UI/Game';
import { Question } from './UI/Question';
import { SizeContext } from './context';
import { Welcome } from './UI/Welcome';
import { EntryLeft } from './UI/Entry/EntryLeft';
import { EntryRight } from './UI/Entry/EntryRight';
import { Droplet } from './UI/Interfaces';

export const App: React.FC = ({ }) => {
  const [showWelcome, setShowWelcome] = useState(true);
  const [startGame, setStartGame] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [correctIsGiven, setCorrectAnswerIsGiven] = useState(false);
  const [startEntry, setStartEntry] = useState(false);
  const [showCountDown, setShowCountDown] = useState(false);

  const [selectedDroplet, setSelectedDroplet] = useState<Droplet>(Droplet.Cloud);

  const [levelCount, setLevelCount] = useState(1);
  const [gameIsStopped, setStopGame] = useState(false);

  const [restart, setRestart] = useState(false);

  const context = useContext(SizeContext);

  useEffect(() => {
    getSize();
    window.addEventListener('resize', handleResize);
    window.addEventListener("orientationchange", handleResize);
    window.screen.orientation.addEventListener("change", handleResize);
  }, [])

  const handleResize = () => {
    getSize();
  }

  const getSize = () => {
    resizeScreen();

    var windowHeight = Math.min(window.screen.height, window.innerHeight);
    var windowWidth = Math.min(window.screen.width, window.innerWidth);

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    if (webkit) {
      windowHeight = window.innerHeight / window.devicePixelRatio;
      windowWidth = window.innerWidth / window.devicePixelRatio;
    }

    var element = document.getElementById("app");
    if (element) {
      element.style.width = context.width + "px";
      element.style.height = context.height + "px";
      //element.style.left = (windowWidth - context.width)/2 + "px"; 
      element.style.top = (windowHeight - context.height) / 2 + "px";
    }
  }

  const resizeScreen = () => {
    var ratio = 1920.0 / 1080.0;
    var windowHeight = Math.min(window.screen.height, window.innerHeight);
    var windowWidth = Math.min(window.screen.width, window.innerWidth);

    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    if (webkit) {
      windowHeight = window.innerHeight;
      windowWidth = window.innerWidth;
    }

    var webWidth = windowWidth;
    var webHeight = windowWidth / ratio;

    if (webHeight > windowHeight) {
      webHeight = windowHeight - 2;
      webWidth = webHeight * ratio;
    }

    context.width = Math.min(webWidth, 1920);
    context.height = Math.min(webHeight, 1080);

    // console.log("web height " + webHeight + " web width " + webWidth);
    //console.log("context height " + context.height + " context width " + context.width);
  }

  const registerAnswer = (status: boolean) => {
    setShowQuestion(false);
    setCorrectAnswerIsGiven(status);
    setTimeout(() => {
      setCorrectAnswerIsGiven(false); // reset it
    }, 300);
  }

  return (
    <div className="App" id="app">

      {showWelcome &&
        <Welcome
          onEnd={() => { setShowWelcome(false); setStartEntry(true) }} />
      }

      {startEntry &&
        <>
          <img src={"images/sandbox.png"} alt='' style={{ width: "100%", height: "100%" }} />
          <EntryLeft />
          <EntryRight
            onEnd={() => {
              setStartEntry(false);
              setStartGame(true);
            }}
          />
        </>
      }
      {startGame &&
        <>
          <img src={"images/sandbox.png"} alt='' style={{ width: "100%", height: "100%" }} />
          <Game
            levelCount={levelCount}
            restart={restart}
            onDropletTapped={(type) => {
              setSelectedDroplet(type);
              setShowQuestion(true)
            }}
            correctIsGiven={correctIsGiven}
            showQuestion={showQuestion}
            onGameOver={() => {
              setStopGame(true);
            }}
          />


          <Question
            gameOver={gameIsStopped}
            showQuestion={showQuestion}
            selectedDroplet={selectedDroplet}
            correctAnswer={() => { registerAnswer(true) }}
            wrongAnswer={() => { registerAnswer(false) }}
            onLevelChange={(level) => {
              setLevelCount(level);
            }}
            onRestart={() => {
              setRestart(true)
              setStopGame(false);
              setShowQuestion(false)
              setTimeout(() => {
                setRestart(false)
              }, 100)
            }}
          />
        </>
      }
    </div>
  );
}

export default App;
