export interface ItemPosition {
    top: number;
    left: number;
}

export interface ItemSize {
    width: number;
    height: number;
}

export interface ItemImage {
    path: string,
    start: number,
    end: number,
    digits: number
}

export interface ItemButton {
    start: number,
    end: number,
}

export interface Item {
    position: ItemPosition;
    size: ItemSize;
    interval: number;
    delay: number;
    button?: ItemButton;
    images?: ItemImage[];
    image?: string;
}

export interface Lottie {
    position: ItemPosition;
    size: ItemSize;
    interval: number;
    delay: number;
    button?: ItemButton;
    lottie: string[];
    audio?: AudioI;
    audio2?: AudioI;
}

export interface AudioI {
    path: string;
    loop?: boolean;
    delay?: number;
}

export enum Droplet {
    Pipe = 1,
    Well,
    Cloud
}

export interface QuestionI {
    text: string,
    answer: boolean
}

export interface TextI {
    text: string,
}