import React, { useState } from 'react';
import { Item } from "../../Interfaces";
import '../AnimateItemButton.css';
import ReactAudioPlayer from 'react-audio-player';

export interface ButtonItemProps {
    item: Item;
    shouldHearClickSound: boolean;
    onTap: () => void;
}

export const ButtonItem: React.FC<ButtonItemProps> = ({ item, shouldHearClickSound, onTap }: ButtonItemProps) => {
    const [playButtonClickAudio, setPlayButtonClickAudio] = useState(false);

    const onTapButton = () => {
        if (!shouldHearClickSound) {
            return;
        }
        setPlayButtonClickAudio(true);
        setTimeout(() => {
            setPlayButtonClickAudio(false);
        }, 500);
    }

    return (
        <>
        {playButtonClickAudio &&
                <ReactAudioPlayer
                    src={"audio/button_click.mp3"}
                    autoPlay
                    loop = {false}
                />
            }
        <button className='AnimatePush' style={{
            width: item.size.width, height: item.size.height, top: item.position.top, left: item.position.left,
            position: 'absolute'
        }} onClick={() => {
            onTapButton();
            setTimeout(() => {
                onTap();
            }, 600);
        }} >
            <img src={item.image} alt='' style={{ width: item.size.width, height: item.size.height }} />
        </button>
        </>
    );
};